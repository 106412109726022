import { WordArray } from './core.js';
import { BlockCipher } from './cipher-core.js';

// Permuted Choice 1 constants
const PC1 = [57, 49, 41, 33, 25, 17, 9, 1, 58, 50, 42, 34, 26, 18, 10, 2, 59, 51, 43, 35, 27, 19, 11, 3, 60, 52, 44, 36, 63, 55, 47, 39, 31, 23, 15, 7, 62, 54, 46, 38, 30, 22, 14, 6, 61, 53, 45, 37, 29, 21, 13, 5, 28, 20, 12, 4];

// Permuted Choice 2 constants
const PC2 = [14, 17, 11, 24, 1, 5, 3, 28, 15, 6, 21, 10, 23, 19, 12, 4, 26, 8, 16, 7, 27, 20, 13, 2, 41, 52, 31, 37, 47, 55, 30, 40, 51, 45, 33, 48, 44, 49, 39, 56, 34, 53, 46, 42, 50, 36, 29, 32];

// Cumulative bit shift constants
const BIT_SHIFTS = [1, 2, 4, 6, 8, 10, 12, 14, 15, 17, 19, 21, 23, 25, 27, 28];

// SBOXes and round permutation constants
const SBOX_P = [{
  0x0: 0x808200,
  0x10000000: 0x8000,
  0x20000000: 0x808002,
  0x30000000: 0x2,
  0x40000000: 0x200,
  0x50000000: 0x808202,
  0x60000000: 0x800202,
  0x70000000: 0x800000,
  0x80000000: 0x202,
  0x90000000: 0x800200,
  0xa0000000: 0x8200,
  0xb0000000: 0x808000,
  0xc0000000: 0x8002,
  0xd0000000: 0x800002,
  0xe0000000: 0x0,
  0xf0000000: 0x8202,
  0x8000000: 0x0,
  0x18000000: 0x808202,
  0x28000000: 0x8202,
  0x38000000: 0x8000,
  0x48000000: 0x808200,
  0x58000000: 0x200,
  0x68000000: 0x808002,
  0x78000000: 0x2,
  0x88000000: 0x800200,
  0x98000000: 0x8200,
  0xa8000000: 0x808000,
  0xb8000000: 0x800202,
  0xc8000000: 0x800002,
  0xd8000000: 0x8002,
  0xe8000000: 0x202,
  0xf8000000: 0x800000,
  0x1: 0x8000,
  0x10000001: 0x2,
  0x20000001: 0x808200,
  0x30000001: 0x800000,
  0x40000001: 0x808002,
  0x50000001: 0x8200,
  0x60000001: 0x200,
  0x70000001: 0x800202,
  0x80000001: 0x808202,
  0x90000001: 0x808000,
  0xa0000001: 0x800002,
  0xb0000001: 0x8202,
  0xc0000001: 0x202,
  0xd0000001: 0x800200,
  0xe0000001: 0x8002,
  0xf0000001: 0x0,
  0x8000001: 0x808202,
  0x18000001: 0x808000,
  0x28000001: 0x800000,
  0x38000001: 0x200,
  0x48000001: 0x8000,
  0x58000001: 0x800002,
  0x68000001: 0x2,
  0x78000001: 0x8202,
  0x88000001: 0x8002,
  0x98000001: 0x800202,
  0xa8000001: 0x202,
  0xb8000001: 0x808200,
  0xc8000001: 0x800200,
  0xd8000001: 0x0,
  0xe8000001: 0x8200,
  0xf8000001: 0x808002
}, {
  0x0: 0x40084010,
  0x1000000: 0x4000,
  0x2000000: 0x80000,
  0x3000000: 0x40080010,
  0x4000000: 0x40000010,
  0x5000000: 0x40084000,
  0x6000000: 0x40004000,
  0x7000000: 0x10,
  0x8000000: 0x84000,
  0x9000000: 0x40004010,
  0xa000000: 0x40000000,
  0xb000000: 0x84010,
  0xc000000: 0x80010,
  0xd000000: 0x0,
  0xe000000: 0x4010,
  0xf000000: 0x40080000,
  0x800000: 0x40004000,
  0x1800000: 0x84010,
  0x2800000: 0x10,
  0x3800000: 0x40004010,
  0x4800000: 0x40084010,
  0x5800000: 0x40000000,
  0x6800000: 0x80000,
  0x7800000: 0x40080010,
  0x8800000: 0x80010,
  0x9800000: 0x0,
  0xa800000: 0x4000,
  0xb800000: 0x40080000,
  0xc800000: 0x40000010,
  0xd800000: 0x84000,
  0xe800000: 0x40084000,
  0xf800000: 0x4010,
  0x10000000: 0x0,
  0x11000000: 0x40080010,
  0x12000000: 0x40004010,
  0x13000000: 0x40084000,
  0x14000000: 0x40080000,
  0x15000000: 0x10,
  0x16000000: 0x84010,
  0x17000000: 0x4000,
  0x18000000: 0x4010,
  0x19000000: 0x80000,
  0x1a000000: 0x80010,
  0x1b000000: 0x40000010,
  0x1c000000: 0x84000,
  0x1d000000: 0x40004000,
  0x1e000000: 0x40000000,
  0x1f000000: 0x40084010,
  0x10800000: 0x84010,
  0x11800000: 0x80000,
  0x12800000: 0x40080000,
  0x13800000: 0x4000,
  0x14800000: 0x40004000,
  0x15800000: 0x40084010,
  0x16800000: 0x10,
  0x17800000: 0x40000000,
  0x18800000: 0x40084000,
  0x19800000: 0x40000010,
  0x1a800000: 0x40004010,
  0x1b800000: 0x80010,
  0x1c800000: 0x0,
  0x1d800000: 0x4010,
  0x1e800000: 0x40080010,
  0x1f800000: 0x84000
}, {
  0x0: 0x104,
  0x100000: 0x0,
  0x200000: 0x4000100,
  0x300000: 0x10104,
  0x400000: 0x10004,
  0x500000: 0x4000004,
  0x600000: 0x4010104,
  0x700000: 0x4010000,
  0x800000: 0x4000000,
  0x900000: 0x4010100,
  0xa00000: 0x10100,
  0xb00000: 0x4010004,
  0xc00000: 0x4000104,
  0xd00000: 0x10000,
  0xe00000: 0x4,
  0xf00000: 0x100,
  0x80000: 0x4010100,
  0x180000: 0x4010004,
  0x280000: 0x0,
  0x380000: 0x4000100,
  0x480000: 0x4000004,
  0x580000: 0x10000,
  0x680000: 0x10004,
  0x780000: 0x104,
  0x880000: 0x4,
  0x980000: 0x100,
  0xa80000: 0x4010000,
  0xb80000: 0x10104,
  0xc80000: 0x10100,
  0xd80000: 0x4000104,
  0xe80000: 0x4010104,
  0xf80000: 0x4000000,
  0x1000000: 0x4010100,
  0x1100000: 0x10004,
  0x1200000: 0x10000,
  0x1300000: 0x4000100,
  0x1400000: 0x100,
  0x1500000: 0x4010104,
  0x1600000: 0x4000004,
  0x1700000: 0x0,
  0x1800000: 0x4000104,
  0x1900000: 0x4000000,
  0x1a00000: 0x4,
  0x1b00000: 0x10100,
  0x1c00000: 0x4010000,
  0x1d00000: 0x104,
  0x1e00000: 0x10104,
  0x1f00000: 0x4010004,
  0x1080000: 0x4000000,
  0x1180000: 0x104,
  0x1280000: 0x4010100,
  0x1380000: 0x0,
  0x1480000: 0x10004,
  0x1580000: 0x4000100,
  0x1680000: 0x100,
  0x1780000: 0x4010004,
  0x1880000: 0x10000,
  0x1980000: 0x4010104,
  0x1a80000: 0x10104,
  0x1b80000: 0x4000004,
  0x1c80000: 0x4000104,
  0x1d80000: 0x4010000,
  0x1e80000: 0x4,
  0x1f80000: 0x10100
}, {
  0x0: 0x80401000,
  0x10000: 0x80001040,
  0x20000: 0x401040,
  0x30000: 0x80400000,
  0x40000: 0x0,
  0x50000: 0x401000,
  0x60000: 0x80000040,
  0x70000: 0x400040,
  0x80000: 0x80000000,
  0x90000: 0x400000,
  0xa0000: 0x40,
  0xb0000: 0x80001000,
  0xc0000: 0x80400040,
  0xd0000: 0x1040,
  0xe0000: 0x1000,
  0xf0000: 0x80401040,
  0x8000: 0x80001040,
  0x18000: 0x40,
  0x28000: 0x80400040,
  0x38000: 0x80001000,
  0x48000: 0x401000,
  0x58000: 0x80401040,
  0x68000: 0x0,
  0x78000: 0x80400000,
  0x88000: 0x1000,
  0x98000: 0x80401000,
  0xa8000: 0x400000,
  0xb8000: 0x1040,
  0xc8000: 0x80000000,
  0xd8000: 0x400040,
  0xe8000: 0x401040,
  0xf8000: 0x80000040,
  0x100000: 0x400040,
  0x110000: 0x401000,
  0x120000: 0x80000040,
  0x130000: 0x0,
  0x140000: 0x1040,
  0x150000: 0x80400040,
  0x160000: 0x80401000,
  0x170000: 0x80001040,
  0x180000: 0x80401040,
  0x190000: 0x80000000,
  0x1a0000: 0x80400000,
  0x1b0000: 0x401040,
  0x1c0000: 0x80001000,
  0x1d0000: 0x400000,
  0x1e0000: 0x40,
  0x1f0000: 0x1000,
  0x108000: 0x80400000,
  0x118000: 0x80401040,
  0x128000: 0x0,
  0x138000: 0x401000,
  0x148000: 0x400040,
  0x158000: 0x80000000,
  0x168000: 0x80001040,
  0x178000: 0x40,
  0x188000: 0x80000040,
  0x198000: 0x1000,
  0x1a8000: 0x80001000,
  0x1b8000: 0x80400040,
  0x1c8000: 0x1040,
  0x1d8000: 0x80401000,
  0x1e8000: 0x400000,
  0x1f8000: 0x401040
}, {
  0x0: 0x80,
  0x1000: 0x1040000,
  0x2000: 0x40000,
  0x3000: 0x20000000,
  0x4000: 0x20040080,
  0x5000: 0x1000080,
  0x6000: 0x21000080,
  0x7000: 0x40080,
  0x8000: 0x1000000,
  0x9000: 0x20040000,
  0xa000: 0x20000080,
  0xb000: 0x21040080,
  0xc000: 0x21040000,
  0xd000: 0x0,
  0xe000: 0x1040080,
  0xf000: 0x21000000,
  0x800: 0x1040080,
  0x1800: 0x21000080,
  0x2800: 0x80,
  0x3800: 0x1040000,
  0x4800: 0x40000,
  0x5800: 0x20040080,
  0x6800: 0x21040000,
  0x7800: 0x20000000,
  0x8800: 0x20040000,
  0x9800: 0x0,
  0xa800: 0x21040080,
  0xb800: 0x1000080,
  0xc800: 0x20000080,
  0xd800: 0x21000000,
  0xe800: 0x1000000,
  0xf800: 0x40080,
  0x10000: 0x40000,
  0x11000: 0x80,
  0x12000: 0x20000000,
  0x13000: 0x21000080,
  0x14000: 0x1000080,
  0x15000: 0x21040000,
  0x16000: 0x20040080,
  0x17000: 0x1000000,
  0x18000: 0x21040080,
  0x19000: 0x21000000,
  0x1a000: 0x1040000,
  0x1b000: 0x20040000,
  0x1c000: 0x40080,
  0x1d000: 0x20000080,
  0x1e000: 0x0,
  0x1f000: 0x1040080,
  0x10800: 0x21000080,
  0x11800: 0x1000000,
  0x12800: 0x1040000,
  0x13800: 0x20040080,
  0x14800: 0x20000000,
  0x15800: 0x1040080,
  0x16800: 0x80,
  0x17800: 0x21040000,
  0x18800: 0x40080,
  0x19800: 0x21040080,
  0x1a800: 0x0,
  0x1b800: 0x21000000,
  0x1c800: 0x1000080,
  0x1d800: 0x40000,
  0x1e800: 0x20040000,
  0x1f800: 0x20000080
}, {
  0x0: 0x10000008,
  0x100: 0x2000,
  0x200: 0x10200000,
  0x300: 0x10202008,
  0x400: 0x10002000,
  0x500: 0x200000,
  0x600: 0x200008,
  0x700: 0x10000000,
  0x800: 0x0,
  0x900: 0x10002008,
  0xa00: 0x202000,
  0xb00: 0x8,
  0xc00: 0x10200008,
  0xd00: 0x202008,
  0xe00: 0x2008,
  0xf00: 0x10202000,
  0x80: 0x10200000,
  0x180: 0x10202008,
  0x280: 0x8,
  0x380: 0x200000,
  0x480: 0x202008,
  0x580: 0x10000008,
  0x680: 0x10002000,
  0x780: 0x2008,
  0x880: 0x200008,
  0x980: 0x2000,
  0xa80: 0x10002008,
  0xb80: 0x10200008,
  0xc80: 0x0,
  0xd80: 0x10202000,
  0xe80: 0x202000,
  0xf80: 0x10000000,
  0x1000: 0x10002000,
  0x1100: 0x10200008,
  0x1200: 0x10202008,
  0x1300: 0x2008,
  0x1400: 0x200000,
  0x1500: 0x10000000,
  0x1600: 0x10000008,
  0x1700: 0x202000,
  0x1800: 0x202008,
  0x1900: 0x0,
  0x1a00: 0x8,
  0x1b00: 0x10200000,
  0x1c00: 0x2000,
  0x1d00: 0x10002008,
  0x1e00: 0x10202000,
  0x1f00: 0x200008,
  0x1080: 0x8,
  0x1180: 0x202000,
  0x1280: 0x200000,
  0x1380: 0x10000008,
  0x1480: 0x10002000,
  0x1580: 0x2008,
  0x1680: 0x10202008,
  0x1780: 0x10200000,
  0x1880: 0x10202000,
  0x1980: 0x10200008,
  0x1a80: 0x2000,
  0x1b80: 0x202008,
  0x1c80: 0x200008,
  0x1d80: 0x0,
  0x1e80: 0x10000000,
  0x1f80: 0x10002008
}, {
  0x0: 0x100000,
  0x10: 0x2000401,
  0x20: 0x400,
  0x30: 0x100401,
  0x40: 0x2100401,
  0x50: 0x0,
  0x60: 0x1,
  0x70: 0x2100001,
  0x80: 0x2000400,
  0x90: 0x100001,
  0xa0: 0x2000001,
  0xb0: 0x2100400,
  0xc0: 0x2100000,
  0xd0: 0x401,
  0xe0: 0x100400,
  0xf0: 0x2000000,
  0x8: 0x2100001,
  0x18: 0x0,
  0x28: 0x2000401,
  0x38: 0x2100400,
  0x48: 0x100000,
  0x58: 0x2000001,
  0x68: 0x2000000,
  0x78: 0x401,
  0x88: 0x100401,
  0x98: 0x2000400,
  0xa8: 0x2100000,
  0xb8: 0x100001,
  0xc8: 0x400,
  0xd8: 0x2100401,
  0xe8: 0x1,
  0xf8: 0x100400,
  0x100: 0x2000000,
  0x110: 0x100000,
  0x120: 0x2000401,
  0x130: 0x2100001,
  0x140: 0x100001,
  0x150: 0x2000400,
  0x160: 0x2100400,
  0x170: 0x100401,
  0x180: 0x401,
  0x190: 0x2100401,
  0x1a0: 0x100400,
  0x1b0: 0x1,
  0x1c0: 0x0,
  0x1d0: 0x2100000,
  0x1e0: 0x2000001,
  0x1f0: 0x400,
  0x108: 0x100400,
  0x118: 0x2000401,
  0x128: 0x2100001,
  0x138: 0x1,
  0x148: 0x2000000,
  0x158: 0x100000,
  0x168: 0x401,
  0x178: 0x2100400,
  0x188: 0x2000001,
  0x198: 0x2100000,
  0x1a8: 0x0,
  0x1b8: 0x2100401,
  0x1c8: 0x100401,
  0x1d8: 0x400,
  0x1e8: 0x2000400,
  0x1f8: 0x100001
}, {
  0x0: 0x8000820,
  0x1: 0x20000,
  0x2: 0x8000000,
  0x3: 0x20,
  0x4: 0x20020,
  0x5: 0x8020820,
  0x6: 0x8020800,
  0x7: 0x800,
  0x8: 0x8020000,
  0x9: 0x8000800,
  0xa: 0x20800,
  0xb: 0x8020020,
  0xc: 0x820,
  0xd: 0x0,
  0xe: 0x8000020,
  0xf: 0x20820,
  0x80000000: 0x800,
  0x80000001: 0x8020820,
  0x80000002: 0x8000820,
  0x80000003: 0x8000000,
  0x80000004: 0x8020000,
  0x80000005: 0x20800,
  0x80000006: 0x20820,
  0x80000007: 0x20,
  0x80000008: 0x8000020,
  0x80000009: 0x820,
  0x8000000a: 0x20020,
  0x8000000b: 0x8020800,
  0x8000000c: 0x0,
  0x8000000d: 0x8020020,
  0x8000000e: 0x8000800,
  0x8000000f: 0x20000,
  0x10: 0x20820,
  0x11: 0x8020800,
  0x12: 0x20,
  0x13: 0x800,
  0x14: 0x8000800,
  0x15: 0x8000020,
  0x16: 0x8020020,
  0x17: 0x20000,
  0x18: 0x0,
  0x19: 0x20020,
  0x1a: 0x8020000,
  0x1b: 0x8000820,
  0x1c: 0x8020820,
  0x1d: 0x20800,
  0x1e: 0x820,
  0x1f: 0x8000000,
  0x80000010: 0x20000,
  0x80000011: 0x800,
  0x80000012: 0x8020020,
  0x80000013: 0x20820,
  0x80000014: 0x20,
  0x80000015: 0x8020000,
  0x80000016: 0x8000000,
  0x80000017: 0x8000820,
  0x80000018: 0x8020820,
  0x80000019: 0x8000020,
  0x8000001a: 0x8000800,
  0x8000001b: 0x0,
  0x8000001c: 0x20800,
  0x8000001d: 0x820,
  0x8000001e: 0x20020,
  0x8000001f: 0x8020800
}];

// Masks that select the SBOX input
const SBOX_MASK = [0xf8000001, 0x1f800000, 0x01f80000, 0x001f8000, 0x0001f800, 0x00001f80, 0x000001f8, 0x8000001f];

// Swap bits across the left and right words
function exchangeLR(offset, mask) {
  const t = (this._lBlock >>> offset ^ this._rBlock) & mask;
  this._rBlock ^= t;
  this._lBlock ^= t << offset;
}
function exchangeRL(offset, mask) {
  const t = (this._rBlock >>> offset ^ this._lBlock) & mask;
  this._lBlock ^= t;
  this._rBlock ^= t << offset;
}

/**
 * DES block cipher algorithm.
 */
export class DESAlgo extends BlockCipher {
  constructor(xformMode, key, cfg) {
    super(xformMode, key, cfg);

    // blickSize is an instance field and should set in constructor.
    // Both DESAlgo and TripleDESAlgo.
    this.blockSize = 64 / 32;
  }
  _doReset() {
    // Shortcuts
    const key = this._key;
    const keyWords = key.words;

    // Select 56 bits according to PC1
    const keyBits = [];
    for (let i = 0; i < 56; i += 1) {
      const keyBitPos = PC1[i] - 1;
      keyBits[i] = keyWords[keyBitPos >>> 5] >>> 31 - keyBitPos % 32 & 1;
    }

    // Assemble 16 subkeys
    this._subKeys = [];
    const subKeys = this._subKeys;
    for (let nSubKey = 0; nSubKey < 16; nSubKey += 1) {
      // Create subkey
      subKeys[nSubKey] = [];
      const subKey = subKeys[nSubKey];

      // Shortcut
      const bitShift = BIT_SHIFTS[nSubKey];

      // Select 48 bits according to PC2
      for (let i = 0; i < 24; i += 1) {
        // Select from the left 28 key bits
        subKey[i / 6 | 0] |= keyBits[(PC2[i] - 1 + bitShift) % 28] << 31 - i % 6;

        // Select from the right 28 key bits
        subKey[4 + (i / 6 | 0)] |= keyBits[28 + (PC2[i + 24] - 1 + bitShift) % 28] << 31 - i % 6;
      }

      // Since each subkey is applied to an expanded 32-bit input,
      // the subkey can be broken into 8 values scaled to 32-bits,
      // which allows the key to be used without expansion
      subKey[0] = subKey[0] << 1 | subKey[0] >>> 31;
      for (let i = 1; i < 7; i += 1) {
        subKey[i] >>>= (i - 1) * 4 + 3;
      }
      subKey[7] = subKey[7] << 5 | subKey[7] >>> 27;
    }

    // Compute inverse subkeys
    this._invSubKeys = [];
    const invSubKeys = this._invSubKeys;
    for (let i = 0; i < 16; i += 1) {
      invSubKeys[i] = subKeys[15 - i];
    }
  }
  encryptBlock(M, offset) {
    this._doCryptBlock(M, offset, this._subKeys);
  }
  decryptBlock(M, offset) {
    this._doCryptBlock(M, offset, this._invSubKeys);
  }
  _doCryptBlock(M, offset, subKeys) {
    const _M = M;

    // Get input
    this._lBlock = M[offset];
    this._rBlock = M[offset + 1];

    // Initial permutation
    exchangeLR.call(this, 4, 0x0f0f0f0f);
    exchangeLR.call(this, 16, 0x0000ffff);
    exchangeRL.call(this, 2, 0x33333333);
    exchangeRL.call(this, 8, 0x00ff00ff);
    exchangeLR.call(this, 1, 0x55555555);

    // Rounds
    for (let round = 0; round < 16; round += 1) {
      // Shortcuts
      const subKey = subKeys[round];
      const lBlock = this._lBlock;
      const rBlock = this._rBlock;

      // Feistel function
      let f = 0;
      for (let i = 0; i < 8; i += 1) {
        f |= SBOX_P[i][((rBlock ^ subKey[i]) & SBOX_MASK[i]) >>> 0];
      }
      this._lBlock = rBlock;
      this._rBlock = lBlock ^ f;
    }

    // Undo swap from last round
    const t = this._lBlock;
    this._lBlock = this._rBlock;
    this._rBlock = t;

    // Final permutation
    exchangeLR.call(this, 1, 0x55555555);
    exchangeRL.call(this, 8, 0x00ff00ff);
    exchangeRL.call(this, 2, 0x33333333);
    exchangeLR.call(this, 16, 0x0000ffff);
    exchangeLR.call(this, 4, 0x0f0f0f0f);

    // Set output
    _M[offset] = this._lBlock;
    _M[offset + 1] = this._rBlock;
  }
}
DESAlgo.keySize = 64 / 32;
DESAlgo.ivSize = 64 / 32;
// blickSize is an instance field and should set in constructor.

/**
 * Shortcut functions to the cipher's object interface.
 *
 * @example
 *
 *     var ciphertext = CryptoJS.DES.encrypt(message, key, cfg);
 *     var plaintext  = CryptoJS.DES.decrypt(ciphertext, key, cfg);
 */
export const DES = BlockCipher._createHelper(DESAlgo);

/**
 * Triple-DES block cipher algorithm.
 */
export class TripleDESAlgo extends BlockCipher {
  _doReset() {
    // Shortcuts
    const key = this._key;
    const keyWords = key.words;
    // Make sure the key length is valid (64, 128 or >= 192 bit)
    if (keyWords.length !== 2 && keyWords.length !== 4 && keyWords.length < 6) {
      throw new Error('Invalid key length - 3DES requires the key length to be 64, 128, 192 or >192.');
    }

    // Extend the key according to the keying options defined in 3DES standard
    const key1 = keyWords.slice(0, 2);
    const key2 = keyWords.length < 4 ? keyWords.slice(0, 2) : keyWords.slice(2, 4);
    const key3 = keyWords.length < 6 ? keyWords.slice(0, 2) : keyWords.slice(4, 6);

    // Create DES instances
    this._des1 = DESAlgo.createEncryptor(WordArray.create(key1));
    this._des2 = DESAlgo.createEncryptor(WordArray.create(key2));
    this._des3 = DESAlgo.createEncryptor(WordArray.create(key3));
  }
  encryptBlock(M, offset) {
    this._des1.encryptBlock(M, offset);
    this._des2.decryptBlock(M, offset);
    this._des3.encryptBlock(M, offset);
  }
  decryptBlock(M, offset) {
    this._des3.decryptBlock(M, offset);
    this._des2.encryptBlock(M, offset);
    this._des1.decryptBlock(M, offset);
  }
}
TripleDESAlgo.keySize = 192 / 32;
TripleDESAlgo.ivSize = 64 / 32;
// blickSize is an instance field and should set in constructor.

/**
 * Shortcut functions to the cipher's object interface.
 *
 * @example
 *
 *     var ciphertext = CryptoJS.TripleDES.encrypt(message, key, cfg);
 *     var plaintext  = CryptoJS.TripleDES.decrypt(ciphertext, key, cfg);
 */
export const TripleDES = BlockCipher._createHelper(TripleDESAlgo);