import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { ObservableBoolean } from '@studiohyperdrive/rxjs-utils';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthenticationService } from '@cjm/shared/authentication/auth';
import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';

import { UserService } from '../../data';
import { getAuthFailType } from '../helpers';

export const IsAuthenticatedGuard: CanActivateFn = (route: ActivatedRouteSnapshot): ObservableBoolean => {
	// Iben: Fetch all injectables
	const router: Router = inject(Router);
	const userService: UserService = inject(UserService);
	const i18nService: I18nService = inject(I18nService);
	const authService: AuthenticationService = inject(AuthenticationService);

	return combineLatest([
		userService.user$,
		// Iben: We only allow this to emit once the refreshUser call is ready
		userService.loading$.pipe(filter((loading) => !loading))
	]).pipe(
		map(([user]) => {
			// Iben: Continue if user is logged in
			if (user) {
				return true;
			}

			const failType = getAuthFailType(route.queryParams);

			if (failType) {
				authService.setAuthenticationFailed(failType);
				router.navigate([
					'/',
					i18nService.currentLanguage,
					VLoketAppRoutePaths.Redirects,
					VLoketAppRoutePaths.AuthenticationFailed
				]);

				return false;
			}

			// Iben: Route to main page in case the user isn't logged in
			router.navigate(['', i18nService.currentLanguage]);
			return false;
		})
	);
};
