import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Params, Router, UrlTree } from '@angular/router';
import { I18nService } from '@studiohyperdrive/ngx-i18n';
import { validateContent } from '@studiohyperdrive/rxjs-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VLoketAppRoutePaths } from '@cjm/shared/route-paths';

import { UserEntity } from '../../interfaces';
import { UserService } from '../../services';

/**
 * AssociationAuthenticatedGuard
 *
 * Checks if the current user is authenticated for an association and has a valid vCode.
 * If the user is not authenticated or does not have a valid vCode, it redirects to the original route.
 * If the user is authenticated with a valid vCode, it redirects to the myAssociations route.
 *
 * @param {ActivatedRouteSnapshot} route - The activated route snapshot object.
 * @returns {boolean|Observable<boolean|UrlTree>} - The result of the association authentication check,
 *                                                 or an Observable that resolves to the result.
 */
export const AssociationAuthenticatedGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot
): boolean | Observable<boolean | UrlTree> => {
	const i18nService: I18nService = inject(I18nService);
	const userService: UserService = inject(UserService);
	const router: Router = inject(Router);
	const queryParams: Params = route.queryParams;

	if (queryParams?.remoteAuthSuccess !== 'true') {
		return true;
	}

	return userService.user$.pipe(
		validateContent(),
		map((user: UserEntity) => {
			const vCode = user?.company?.vCode;

			if (typeof vCode === 'string' && vCode.length > 0) {
				return router.createUrlTree([i18nService.currentLanguage, VLoketAppRoutePaths.MyAssociation]);
			}

			return true;
		})
	);
};
