import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CJMHttpClientService } from '@cjm/shared/core';
import { VLoketEndpoints } from '@cjm/shared/endpoint';
import { environment } from 'environments';

import { UserContextKey, UserSessionEntity, UserSessionEntityResult } from '../interfaces';

@Injectable()
export class UserApiService {
	constructor(private readonly httpClient: CJMHttpClientService) {
		httpClient.setIncludeLanguage(false);
	}

	/**
	 * Get information on the user and their session
	 */
	public getUserSession(): Observable<UserSessionEntity> {
		return this.httpClient.get<UserSessionEntityResult>(VLoketEndpoints.User.UserSession(), {}, true).pipe(
			map((session) => {
				// Iben: Return undefined if there is no user
				if (session['@type'] === 'AnoniemeGebruiker') {
					return;
				}

				// Iben: Convert the user
				return {
					cookie: {
						expiresAt: session.cookie.vervaltijdstip
					},
					metaData: {
						expiresAt: session.metaGegevens.vervaltijdstip,
						lastLoggedInAt: session.metaGegevens.laatsteAanmelding,
						previousLoggedInAt: session.metaGegevens.vorigeAanmelding,
						lastUsedAt: session.metaGegevens.laatsteGebruikt,
						lifetime: session.metaGegevens.levensduur,
						sessionStartedAt: session.metaGegevens.startSessie
					},
					user: {
						firstName: session.gebruiker.voornaam,
						name: session.gebruiker.familienaam,
						insz: session.gebruiker?.insz,
						isCivilian: session.gebruiker.alsBurger,
						isCompany: session.gebruiker.alsOrganisatie,
						language: session.gebruiker.taal,
						id: session.gebruiker.id,
						targetCode: session.gebruiker.doelgroepCode,
						...(session.gebruiker.organisatie
							? // Denis: using eslint-disable to temporarly disable the indenting rule here.
								{
									company: {
										number: session.gebruiker.organisatie.nummer,
										name: session.gebruiker.organisatie.naam,
										...(session.gebruiker.organisatie.hasOwnProperty('vCode')
											? { vCode: session.gebruiker.organisatie.vCode }
											: {}),
										isLegalRepresentative:
											session.gebruiker.organisatie.isWettelijkVertegenwoordiger || false,
										isMandateAdministrator:
											session.gebruiker.organisatie.isMandaatBeheerder || false,
										hasSanctionsAccess:
											session.gebruiker.organisatie.isSanctieregisterToegankelijk || false
									}
								}
							: {})
						/* eslint-enable */
					}
				};
			})
		);
	}

	/**
	 * Switch company of user
	 */
	public switchCompany(): Observable<void> {
		return this.httpClient.get('cjm/switch', {}, true);
	}

	/**
	 * Set the user context for a user
	 *
	 * @param key - The key for the user context
	 * @param waarde - The value we want to save in the user context
	 * @param geldigheid - The amount of time the value should be set in the user context
	 */
	public setUserContext(key: UserContextKey, waarde: unknown, geldigheid: number) {
		// Iben: Set the context of the spotlight cookie
		return this.httpClient.put(VLoketEndpoints.User.UserContext(key), { waarde, geldigheid }).pipe(
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of())
		);
	}

	/**
	 * Returns the user context for a specific key
	 *
	 * @template Context - The type of the response
	 * @param key - The key for which you get the data
	 */
	public getUserContext<Context>(key: UserContextKey): Observable<Context> {
		return this.httpClient.get<{ waarde: Context }>(VLoketEndpoints.User.UserContext(key), {}, true).pipe(
			map(({ waarde }) => waarde),
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of(undefined))
		);
	}

	/**
	 * Delete the user context for a specific key
	 *
	 * @param key - The key for which you get the data
	 */
	public deleteUserContext(key: UserContextKey): Observable<void> {
		return this.httpClient.delete<void>(VLoketEndpoints.User.UserContext(key), {}, true).pipe(
			// Iben: Ignore the error as it shouldn't stop users in their flow
			catchError(() => of(undefined))
		);
	}

	public setSasContext(companyNumber: string): Observable<void> {
		const payload = {
			doelgroep: 'EA', // "Economische actoren".
			organisatie: companyNumber,
			brontoepassing: environment.sas.sourceUuid,
			doeltoepassing: environment.sas.targetUuid
		};

		return this.httpClient.directPost(environment.sas.url, payload);
	}
}
