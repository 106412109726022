import {
	ChangeDetectorRef,
	Directive,
	EmbeddedViewRef,
	Input,
	OnDestroy,
	TemplateRef,
	ViewContainerRef
} from '@angular/core';
import { isArray } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { UserTargetCodeEntityKeys } from '@cjm/shared/types';

import { UserService } from '../../data';

/**
 * Based upon `*ngIf`. See https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts
 */
@Directive({
	selector: '[hasRole]',
	standalone: true
})
export class HasRoleDirective implements OnDestroy {
	private readonly destroyed$ = new Subject();

	private thenTemplateRef: TemplateRef<any> | null = null;
	private thenViewRef: EmbeddedViewRef<any> | null = null;
	private elseTemplateRef: TemplateRef<any> | null = null;
	private elseViewRef: EmbeddedViewRef<any> | null = null;
	private allowedRoles: UserTargetCodeEntityKeys[] = [];

	constructor(
		private readonly userService: UserService,
		private readonly templateRef: TemplateRef<any>,
		private readonly viewContainer: ViewContainerRef,
		private readonly cdRef: ChangeDetectorRef
	) {
		this.thenTemplateRef = templateRef;
	}

	@Input()
	public set hasRole(role: UserTargetCodeEntityKeys | UserTargetCodeEntityKeys[]) {
		this.allowedRoles = isArray(role) ? role : [role];
		this.updateView();
	}
	@Input()
	public set hasRoleElse(ngTemplate: TemplateRef<any>) {
		this.elseTemplateRef = ngTemplate;
		this.elseViewRef = null;
		this.updateView();
	}

	public ngOnDestroy(): void {
		this.destroyed$.next(undefined);
		this.destroyed$.complete();
	}

	private updateView() {
		this.userService
			.hasRole(this.allowedRoles)
			.pipe(
				tap((isAllowedForRole) => {
					if (isAllowedForRole) {
						if (!this.thenViewRef) {
							this.viewContainer.clear();
							this.elseViewRef = null;
							if (this.thenTemplateRef) {
								this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef);
							}
						}
					} else {
						if (!this.elseViewRef) {
							this.viewContainer.clear();
							this.thenViewRef = null;
							if (this.elseTemplateRef) {
								this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
							}
						}
					}

					// Iben: Detect the changes so that the view gets updated
					this.cdRef.detectChanges();
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}
}
