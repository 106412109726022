import { BlockCipherMode } from './cipher-core.js';

/**
 * Electronic Codebook block mode.
 */
export class ECB extends BlockCipherMode {}
ECB.Encryptor = class extends ECB {
  processBlock(words, offset) {
    this._cipher.encryptBlock(words, offset);
  }
};
ECB.Decryptor = class extends ECB {
  processBlock(words, offset) {
    this._cipher.decryptBlock(words, offset);
  }
};